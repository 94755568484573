import React from "react";
import { MDBRow, MDBCol, MDBContainer, MDBAnimation } from "mdbreact";
import Card from "../card/card";
import { sections } from "../../data.json";

export default function Testimonial() {
  const news = sections.find((section) => section.id === "news");
  return (
    <>
      <MDBRow className="ml-0 mr-0 justify-content-center mt-5">
        <h2 className="h2-responsive font-weight-bold">Trending </h2>
      </MDBRow>
      <MDBContainer style={{ width: "100%", maxWidth: "fit-content" }}>
        <MDBRow id="news" className="mt-4 justify-content-center col-12 ">
          {news
            ? news.content.map((contnt) => (
                <MDBCol size="3">
                  <Card {...contnt} />
                </MDBCol>
              ))
            : null}
        </MDBRow>
      </MDBContainer>
    </>
  );
}
