import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBView,
  MDBCardTitle
} from "mdbreact";
import "./card.css";

export default function Card(props) {


    const navigateToLink = (linkUrl) => {
        window.location.href = linkUrl;
    }
  return (
    <MDBCard narrow>
      <MDBView cascade>
        <MDBCardImage
          hover
          overlay="white-slight"
          className="card-img-top"
          src={props.imageSrc}
          alt={props.imageAlt}
          onClick={()=> navigateToLink(props.linkTo)}
        />
      </MDBView>

      <MDBCardBody>

        <MDBCardTitle className="font-weight-bold">
          {props.title}
        </MDBCardTitle>

        <MDBCardText>
          {props.description}
        </MDBCardText>

      </MDBCardBody>
    </MDBCard>
  );
}
