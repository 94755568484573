import React from "react";
import {
  MDBView,
  MDBMask,
  MDBAnimation,
  MDBContainer,
  MDBRow,
} from "mdbreact";
import "./intro.css";
import {intro} from '../../data.json'

export default function Intro() {
  return (
    <MDBView id="intro">
      <MDBMask className="d-flex justify-content-center align-items-center gradient view overlay">
        <MDBContainer>
          <MDBRow>
            <MDBAnimation
              type="fadeInLeft"
              delay=".3s"
              className="white-text text-center"
            >
              <h1 className="h1-responsive font-weight-bold">
                {intro.title}
              </h1>
              <hr className="hr-light" />
              <h6 className="mb-4">
                {intro.description}
              </h6>
            </MDBAnimation>
          </MDBRow>
        </MDBContainer>
      </MDBMask>
    </MDBView>
  );
}
