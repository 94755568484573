import { MDBBtn, MDBRow } from "mdbreact";
import React from "react";
import "./advertisements.css";

const Advertisement = () => {
  return (
    <>
      <div id="advertisements" className="mt-5 mb-5 section-title text-center">
        <h2 className="font-weight-bold h2-responsive">Advertisements</h2>
      </div>
      <MDBRow className="text-center justify-content-center ml-0 mr-0">
        <div className="w-50 row">
          <div className="col-sm-4 col-md-4 advertisement">
            <div className="border rounded">
              <div className="head">
                <h3>Premium Ad</h3>
              </div>
              <div className="price">
                <h3>
                  5000<span className="symbol">₹</span>
                </h3>
              </div>
              <ul className="list-group">
                <li className="list-group-item">20 words</li>
                <li className="list-group-item">3cm * 6cm</li>
                <li className="list-group-item">First Page/ Any Page</li>
                <li className="list-group-item">Classified/Display Ad</li>
                <li className="list-group-item">Color ₹1000 extra</li>
              </ul>
            </div>
          </div>
          <div className="col-sm-4 col-md-4 advertisement ">
            <div className="border rounded">
              <div className="head">
                <h3 className="auto-style1">Professional Ad</h3>
              </div>
              <div className="price">
                <h3>
                  3500<span className="symbol">₹</span>
                </h3>
              </div>
              <ul className="list-group">
                <li className="list-group-item">18 words</li>
                <li className="list-group-item">3cm * 5cm</li>
                <li className="list-group-item">Any Page</li>
                <li className="list-group-item">Classified Ad</li>
                <li className="list-group-item">Color ₹1000 extra</li>
              </ul>
            </div>
          </div>
          <div className="col-sm-4 col-md-4 advertisement">
            <div className="border rounded">
              <div className="head">
                <h3>Classified Ad</h3>
              </div>
              <div className="price">
                <h3>
                  2500<span className="symbol">₹</span>
                </h3>
              </div>
              <ul className="list-group">
                <li className="list-group-item">12 words</li>
                <li className="list-group-item">3cm * 4cm</li>
                <li className="list-group-item">Any Page</li>
                <li className="list-group-item">Classified Ad</li>
                <li className="list-group-item">Color ₹1000 extra</li>
              </ul>
            </div>
          </div>
        </div>
      </MDBRow>
    </>
  );
};

export default Advertisement;
