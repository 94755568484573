import React from "react";

export default function AboutUs() {
  return (
    <section
      id="about-us"
      className="d-flex justify-content-center mb-5 mt-5"
    >
      <div className="col-md-10">
        <h2 className="h2-reponsive mb-5 font-weight-bold text-center">About Us</h2>
        <p style={{ fontSize: "1.5rem" }}>
          Keen to keep the growing of base of Global Tamilians connected to
          their homeland, Idhaya.com was started in 1997 as a free to read site
          for all Idhaya group of magazines. The site went “paid for reading”
          from Feb 2005, offering digital magazine subscriptions for readers to
          pay, subscribe and read the Idhaya magazines on the internet. The
          site also runs free to read sections such as www.Idhaya.com/news /
          and www.Idhaya.com/cinema/ which provides latest news and views on
          current happenings and South Indian Cinema.
        </p>

        <p style={{ fontSize: "1.5rem" }}>
          The site provides advertisement services to brands through Google
          Adsense advertisement service and sources and places direct
          advertisement from the advertisers. The ads are placed in the various
          pages of Idhaya.com website as per the relevance and site design. In
          addition to this Idhaya.com provides magazine subscription services
          through Devices such as iPad, iPhone, Android Phones and Tablets in
          the form of Apps.
        </p>
        <p></p>
      </div>
    </section>
  );
}
