import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import Intro from "./components/intro/intro";
import Testimonial from "./components/testimonial/testimonial";
import React, { useEffect, useState, createContext } from "react";
import Publication from "./components/publications/publications";
import ContactUs from "./components/contact-us/contact-us";
import { sectionNames as sections } from "./data.json";
import AboutUs from "./components/about-us/about-us";
import Advertisement from "./components/advertisements/advertisements";

export const ScrollContext = createContext();
function App() {
  const [currentSection, setCurrentSection] = useState(sections[0]);
  useEffect(() => {
    const navBarHeight = document.getElementById("navbar").scrollHeight;
    const scrollfunc = (e) => {
      const heights = sections.map(
        (a) => document.getElementById(a).offsetTop - navBarHeight
      );
      const currentHeight = window.scrollY;
      const currentSectionId = heights
        .reverse()
        .findIndex((height) => height <= currentHeight);
      if (currentSectionId !== -1) {
        const currSection = sections[sections.length - currentSectionId - 1];
        setCurrentSection(currSection);
      }
      updateNavBar();
    };
    window.addEventListener("scroll", scrollfunc);
    return () => {
      window.removeEventListener("scroll", scrollfunc);
    };
  }, []);

  const updateNavBar = () => {
    const navBar = document.getElementById("navbar");
    if (navBar.scrollHeight + 20 >= window.scrollY) {
      navBar.classList.add("navbar-opaque");
      console.log("If block");
    } else {
      navBar.classList.add("navbar-opaque");
      console.log("Else block");
    }
  };
  return (
    <ScrollContext.Provider value={{ currentSection, setCurrentSection }}>
      <Header />
      <Intro />
      <Testimonial />
      <Publication />
      <Advertisement/>
      <AboutUs/>
      <ContactUs />
      <Footer />
    </ScrollContext.Provider>
  );
}

export default App;
