import React from "react";
import { MDBRow, MDBAnimation } from "mdbreact";

const Publication = () => {
  return (
    <>
      <MDBRow className="ml-0 mr-0 text-center justify-content-center mt-5">
        <h2 className="h2-responsive font-weight-bold">Other Publications</h2>
      </MDBRow>
      <div
        id="other-publications"
        className="mt-5 text-center justify-content-center"
        style={{ width: "100%" }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto auto auto",
            gridRowGap: "20px",
          }}
        >
          <div>
            <a href="#">
              <img
                src="http://www.kungumam.co.in/wrapper/kungumammagazine-wp-538.jpg"
                width="250"
              />
            </a>
          </div>
          <div>
            <a href="#">
              <img
                src="http://www.kungumam.co.in/wrapper/doctor-magazine-wra-141.jpg"
                width="250"
              />
            </a>
          </div>
          <div>
            <a href="#">
              <img
                src="http://www.kungumam.co.in/wrapper/thozhi-magazine-wra-192.jpg"
                width="250"
              />
            </a>
          </div>
          <div>
            <a href="#">
              <img
                src="http://www.kungumam.co.in/wrapper/thosup-magazine-wra-133.jpg"
                width="250"
              />
            </a>
          </div>
          <div>
            <a href="#">
              <img
                src="http://www.kungumam.co.in/wrapper/vannatherai-book-wp-498.jpg"
                width="250"
              />
            </a>
          </div>
          <div>
            <a href="#">
              <img
                src="http://www.kungumam.co.in/wrapper/mutharammagazine-wp-517.jpg"
                width="250"
              />
            </a>
          </div>
          <div>
            <a href="#">
              <img
                src="http://www.kungumam.co.in/wrapper/chimizh-magazine-wp-236.jpg"
                width="250"
              />
            </a>
          </div>
          <div>
            <a href="#">
              <img
                src="http://www.kungumam.co.in/wrapper/aanmegapalan-bookwp-198.jpg"
                width="250"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Publication;
