import React, { useState, useContext } from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
} from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";
import { ScrollContext } from "../../App";
import "./header.css";
import { name, sections } from "../../data.json";

const Header = function () {
  const { currentSection } = useContext(ScrollContext);

  const [isOpen, setOpen] = useState(false);
  const toggleCollapse = () => {
    setOpen((val) => !val);
  };

  const toggleActive = (value) => {
    const navBarHeight = document.getElementById("navbar").scrollHeight;
    const selectedElementTop = document.getElementById(value).offsetTop;
    window.scrollTo({
      top: selectedElementTop - navBarHeight,
      behavior: "smooth",
    });
  };
  return (
    <Router>
      <MDBNavbar
        id="navbar"
        color="rgba-blue-grey-strong"
        dark
        expand="md"
        fixed="top"
      >
        <MDBNavbarBrand>
          <strong className="white-text">{name}</strong>
        </MDBNavbarBrand>
        <MDBNavbarToggler onClick={toggleCollapse} />
        <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
          <MDBNavbarNav right>
            {sections.map((section) => (
              <MDBNavItem
                active={currentSection === section.id}
                onClick={() => toggleActive(section.id)}
              >
                <MDBNavLink to={"#" + section.id}>{section.name}</MDBNavLink>
              </MDBNavItem>
            ))}
            <MDBNavItem>
              <MDBNavLink className="waves-effect waves-light" to="#!">
                <MDBIcon fab icon="twitter" />
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink className="waves-effect waves-light" to="#!">
                <MDBIcon fab icon="google-plus-g" />
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <MDBIcon icon="user" />
                </MDBDropdownToggle>
                <MDBDropdownMenu className="dropdown-default" right={true}>
                  <MDBDropdownItem href="#!">Login</MDBDropdownItem>
                  <MDBDropdownItem href="#!">Sign Up</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    </Router>
  );
};

export default Header;
