import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBContainer,
} from "mdbreact";

export default function ContactUs() {
  return (
    <MDBContainer fluid>
      <MDBRow className="justify-content-center" style={{ height: 600 }}>
        <MDBCol size="4">
          <MDBCard>
            <MDBCardTitle className="font-weight-bold pt-5 text-center animated zoomIn">
              Contact Us
            </MDBCardTitle>
            <MDBCardBody>
              <MDBIcon
                icon="map-marker-alt"
                size="2x"
                className="d-inline-block pt-4"
              />
              <p className="d-inline-block ml-3">
                San Francisco, CA 94126, USA
              </p>
              <br />
              <MDBIcon
                icon="phone"
                size="2x"
                className="teal-text d-inline-block pt-4"
              />
              <p className="d-inline-block ml-3">+ 01 234 567 89</p>
              <br />
              <MDBIcon
                icon="envelope"
                size="2x"
                className="blue-text d-inline-block pt-4"
              />
              <p className="d-inline-block ml-3">contact@example.com</p>
              <br />
              <MDBIcon
                fab
                icon="whatsapp"
                size="2x"
                className="green-text d-inline-block pt-4"
              />
              <p className="d-inline-block ml-3">contact@example.com</p>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol size="4">
          <MDBCard>
            <MDBCardTitle className="font-weight-bold pt-5 text-center">
              Follow us
            </MDBCardTitle>
            <MDBCardBody>
              <MDBIcon
                fab
                icon="twitter"
                size="2x"
                className="blue-text d-inline-block pt-4"
              />
              <p className="d-inline-block ml-3">
                <a href="https://twitter.com">@DummyId</a>
              </p>
              <br />
              <MDBIcon
                fab
                icon="youtube"
                size="2x"
                className="red-text d-inline-block pt-4"
              />
              <p className="d-inline-block ml-3">
                <a href="#">Idhaya Channel</a>
              </p>
              <br />
              <MDBIcon
                fab
                icon="facebook"
                size="2x"
                className="blue-text d-inline-block pt-4"
              />
              <p className="d-inline-block ml-3">
                <a href="#">Facebook</a>
              </p>
              <br />
              <MDBIcon
                fab
                icon="instagram"
                size="2x"
                className="d-inline-block pt-4"
              />
              <p className="d-inline-block ml-3">
                <a href="#">Instagram</a>
              </p>
              <br />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
